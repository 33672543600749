import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Box,
  FormControl,
  FormGroup,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { maskCep, maskPhoneNumber } from "../../utils/masks";
import { stateOptions } from "../../utils/constants";
import useAddress from "../../hooks/useAddress";
import { LoadingButton } from "@mui/lab";
import AvatarPicker from "../../components/AvatarPicker";
import { useAppSelector } from "../../app/store";
import { clearState, userSelector } from "../../redux/user/userSlice";
import Backdrop from "../../components/Backdrop";
import { toast } from "react-toastify";
import Dropzone from "../../components/Dropzone";
import Editor from "../../components/Editor";
import Education from "../../components/MultiInput/Education";
import WorkExperience from "../../components/MultiInput/WorkExperience";
import useCandidateProfile from "../../hooks/useCandidateProfile";

const Profile = () => {
  const userState = useAppSelector(userSelector);

  const [resumeExpanded, setCurriculumExpanded] = useState<boolean>(true);
  const [personalInformationExpanded, setPersonalInformationExpanded] =
    useState<boolean>(true);
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false);
  const [socialMediaExpanded, setSocialMediaExpanded] =
    useState<boolean>(false);

  useEffect(() => {
    setCitiesField(userState.currentUser.state || undefined);
  }, [userState.currentUser.state]);

  useEffect(() => {
    if (userState.isSaveSuccess) {
      toast.success("Perfil salvo com sucesso");
    }
  }, [userState.isSaveSuccess]);

  useEffect(() => {
    () => {
      clearState();
    };
  }, []);

  const { formik } = useCandidateProfile();

  const {
    cities,
    loadingCities,
    stateValue,
    cityIsDisabled,
    onChangeState,
    setCitiesField,
    getAddressFromCep,
  } = useAddress(formik, null);

  return (
    <Box>
      <Typography variant="h5">Perfil</Typography>
      {userState.isFinding ? (
        <Backdrop />
      ) : (
        <Paper sx={{ mb: 2, mt: 2, p: { xs: 1, sm: 2 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Accordion
                expanded={resumeExpanded}
                onChange={() => setCurriculumExpanded(!resumeExpanded)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontWeight={"bold"}>Currículo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                      <FormGroup>
                        <FormControl
                          error={
                            !!(formik.touched.resume && formik.errors.resume)
                          }
                        >
                          <Dropzone
                            field="resume"
                            formik={formik}
                            supportedFiles={["application/pdf"]}
                            helperText={
                              formik.touched.resume && formik.errors.resume
                                ? formik.errors.resume
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={personalInformationExpanded}
                onChange={() =>
                  setPersonalInformationExpanded(!personalInformationExpanded)
                }
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontWeight={"bold"}>
                    Informações pessoais
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                      <FormGroup>
                        <FormControl
                          error={
                            !!(formik.touched.avatar && formik.errors.avatar)
                          }
                        >
                          <AvatarPicker
                            field="avatar"
                            formik={formik}
                            label="Foto"
                            helperText={
                              formik.touched.avatar && formik.errors.avatar
                                ? formik.errors.avatar
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Nome"
                            size="small"
                            onChange={formik.handleChange("first_name")}
                            onBlur={formik.handleBlur("first_name")}
                            value={formik.values.first_name}
                            error={
                              !!(
                                formik.touched.first_name &&
                                formik.errors.first_name
                              )
                            }
                            helperText={
                              formik.touched.first_name &&
                              formik.errors.first_name
                                ? formik.errors.first_name
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Sobrenome"
                            size="small"
                            onChange={formik.handleChange("last_name")}
                            onBlur={formik.handleBlur("last_name")}
                            value={formik.values.last_name}
                            error={
                              !!(
                                formik.touched.last_name &&
                                formik.errors.last_name
                              )
                            }
                            helperText={
                              formik.touched.last_name &&
                              formik.errors.last_name
                                ? formik.errors.last_name
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Telefone"
                            size="small"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "phone_number",
                                maskPhoneNumber(e.target.value)
                              );
                              formik.setFieldTouched("phone_number", true);
                            }}
                            onBlur={formik.handleBlur("phone_number")}
                            value={formik.values.phone_number}
                            error={
                              !!(
                                formik.touched.phone_number &&
                                formik.errors.phone_number
                              )
                            }
                            helperText={
                              formik.touched.phone_number &&
                              formik.errors.phone_number
                                ? formik.errors.phone_number
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="CEP"
                            size="small"
                            onChange={async (e) => {
                              const maskedCep = maskCep(e.target.value);
                              formik.setFieldValue("cep", maskedCep);
                              formik.setFieldTouched("cep", true);
                              if (maskedCep.length === 9) {
                                const address = await getAddressFromCep(
                                  maskedCep
                                );
                                if (address) {
                                  formik.setFieldValue(
                                    "address_1",
                                    address.address_1
                                  );
                                  formik.setFieldTouched("address_1", true);
                                  formik.setFieldValue(
                                    "district",
                                    address.district
                                  );
                                  formik.setFieldTouched("district", true);
                                  formik.setFieldValue("state", address.state);
                                  formik.setFieldTouched("state", true);
                                  setCitiesField(address.state);
                                  formik.setFieldValue("city", address.city);
                                  formik.setFieldTouched("city", true);
                                }
                              }
                            }}
                            onBlur={formik.handleBlur("cep")}
                            value={formik.values.cep}
                            error={!!(formik.touched.cep && formik.errors.cep)}
                            helperText={
                              formik.touched.cep && formik.errors.cep
                                ? formik.errors.cep
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Endereço"
                            size="small"
                            onChange={formik.handleChange("address_1")}
                            onBlur={formik.handleBlur("address_1")}
                            value={formik.values.address_1}
                            error={
                              !!(
                                formik.touched.address_1 &&
                                formik.errors.address_1
                              )
                            }
                            helperText={
                              formik.touched.address_1 &&
                              formik.errors.address_1
                                ? formik.errors.address_1
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Complemento"
                            size="small"
                            onChange={formik.handleChange("address_2")}
                            onBlur={formik.handleBlur("address_2")}
                            value={formik.values.address_2}
                            error={
                              !!(
                                formik.touched.address_2 &&
                                formik.errors.address_2
                              )
                            }
                            helperText={
                              formik.touched.address_2 &&
                              formik.errors.address_2
                                ? formik.errors.address_2
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Bairro"
                            size="small"
                            onChange={formik.handleChange("district")}
                            onBlur={formik.handleBlur("district")}
                            value={formik.values.district}
                            error={
                              !!(
                                formik.touched.district &&
                                formik.errors.district
                              )
                            }
                            helperText={
                              formik.touched.district && formik.errors.district
                                ? formik.errors.district
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            id="state"
                            options={stateOptions}
                            onChange={(_, option) => onChangeState(option)}
                            value={stateValue || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                label="Estado"
                                size="small"
                                onBlur={formik.handleBlur("state")}
                                error={
                                  !!(
                                    formik.touched.state && formik.errors.state
                                  )
                                }
                                helperText={
                                  formik.touched.state && formik.errors.state
                                    ? formik.errors.state
                                    : null
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            id="city"
                            options={cities}
                            onChange={(_, option) => {
                              formik.setFieldValue("city", option || "");
                            }}
                            value={formik.values.city || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                label="Cidade"
                                size="small"
                                onBlur={formik.handleBlur("city")}
                                error={
                                  !!(formik.touched.city && formik.errors.city)
                                }
                                helperText={
                                  formik.touched.city && formik.errors.city
                                    ? formik.errors.city
                                    : null
                                }
                              />
                            )}
                            loading={loadingCities}
                            disabled={cityIsDisabled}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={profileExpanded}
                onChange={() => setProfileExpanded(!profileExpanded)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontWeight={"bold"}>Perfil</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <Education formik={formik} />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <WorkExperience formik={formik} />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Sumário Profissional"
                            size="small"
                            multiline
                            rows={8}
                            onChange={formik.handleChange("summary")}
                            onBlur={formik.handleBlur("summary")}
                            value={formik.values.summary}
                            error={
                              !!(
                                formik.touched.summary && formik.errors.summary
                              )
                            }
                            helperText={
                              formik.touched.summary && formik.errors.summary
                                ? formik.errors.summary
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Carta de Apresentação"
                            size="small"
                            multiline
                            rows={8}
                            onChange={formik.handleChange("cover_letter")}
                            onBlur={formik.handleBlur("cover_letter")}
                            value={formik.values.cover_letter}
                            error={
                              !!(
                                formik.touched.cover_letter &&
                                formik.errors.cover_letter
                              )
                            }
                            helperText={
                              formik.touched.cover_letter &&
                              formik.errors.cover_letter
                                ? formik.errors.cover_letter
                                : null
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={socialMediaExpanded}
                onChange={() => setSocialMediaExpanded(!socialMediaExpanded)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography fontWeight={"bold"}>Mídias sociais</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="LinkedIn"
                            size="small"
                            onChange={formik.handleChange("linkedin")}
                            onBlur={formik.handleBlur("linkedin")}
                            value={formik.values.linkedin}
                            error={
                              !!(
                                formik.touched.linkedin &&
                                formik.errors.linkedin
                              )
                            }
                            helperText={
                              formik.touched.linkedin && formik.errors.linkedin
                                ? formik.errors.linkedin
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.linkedin.com/in/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Instagram"
                            size="small"
                            onChange={formik.handleChange("instagram")}
                            onBlur={formik.handleBlur("instagram")}
                            value={formik.values.instagram}
                            error={
                              !!(
                                formik.touched.instagram &&
                                formik.errors.instagram
                              )
                            }
                            helperText={
                              formik.touched.instagram &&
                              formik.errors.instagram
                                ? formik.errors.instagram
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.instagram.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="TikTok"
                            size="small"
                            onChange={formik.handleChange("tiktok")}
                            onBlur={formik.handleBlur("tiktok")}
                            value={formik.values.tiktok}
                            error={
                              !!(formik.touched.tiktok && formik.errors.tiktok)
                            }
                            helperText={
                              formik.touched.tiktok && formik.errors.tiktok
                                ? formik.errors.tiktok
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.tiktok.com/@
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="GitHub"
                            size="small"
                            onChange={formik.handleChange("github")}
                            onBlur={formik.handleBlur("github")}
                            value={formik.values.github}
                            error={
                              !!(formik.touched.github && formik.errors.github)
                            }
                            helperText={
                              formik.touched.github && formik.errors.github
                                ? formik.errors.github
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.github.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            label="Facebook"
                            size="small"
                            onChange={formik.handleChange("facebook")}
                            onBlur={formik.handleBlur("facebook")}
                            value={formik.values.facebook}
                            error={
                              !!(
                                formik.touched.facebook &&
                                formik.errors.facebook
                              )
                            }
                            helperText={
                              formik.touched.facebook && formik.errors.facebook
                                ? formik.errors.facebook
                                : null
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  https://www.facebook.com/
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  loading={userState.isSaving}
                  onClick={() => formik.handleSubmit()}
                >
                  Atualizar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default Profile;
