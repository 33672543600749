import React, { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Snackbar,
} from "@mui/material";
import { FormikProps } from "formik";
import { JobParams } from "../../redux/job/jobApi";
import urls from "../../utils/urls";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { userSelector } from "../../redux/user/userSlice";
import CopyToClipboard from "react-copy-to-clipboard";

interface IJobPromotion {
  formik: FormikProps<JobParams>;
}

const JobPromotion: React.FC<IJobPromotion> = ({ formik }) => {
  const { id } = useParams();

  const userState = useAppSelector(userSelector);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const jobUrl = () => {
    if (!id) {
      return "";
    }

    return `${window.location.origin}${urls.companyJobPage
      .replace(":slug", userState.currentUser.company?.slug || "")
      .replace(":job_id", id.toString())}`;
  };

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  return (
    <Accordion expanded>
      <AccordionSummary sx={{ cursor: "auto !important" }}>
        <Typography fontWeight={"bold"}>Divulgação</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Snackbar
            message="Link Copiado"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
          />
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography>Link da vaga:</Typography>
            <CopyToClipboard text={jobUrl()} onCopy={handleCopyUrl}>
              <Typography
                sx={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {jobUrl()}
              </Typography>
            </CopyToClipboard>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default JobPromotion;
