import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../app/store";
import { userSelector } from "../redux/user/userSlice";
import { UserCandidateParams } from "../redux/user/userApi";
import { maskCep, maskPhoneNumber } from "../utils/masks";
import { format, parseISO } from "date-fns";
import { updateCandidate } from "../redux/user/userActions";
import { createCandidate } from "../redux/candidate/candidateActions";
import { CandidateParams } from "../redux/candidate/candidateApi";
import { jobSelector } from "../redux/job/jobSlice";

const dateFormat = "yyyy-MM-dd";

export default function useCandidateProfile() {
  const dispatch = useAppDispatch();

  const userState = useAppSelector(userSelector);
  const jobState = useAppSelector(jobSelector);

  const candidateFormData = async (values: CandidateParams) => {
    const formData = new FormData();
    if (values.form.fields.avatar instanceof File) {
      formData.append(
        "candidate[form][fields][avatar]",
        values.form.fields.avatar
      );
    } else if (typeof values.form.fields.avatar === "string") {
      // TODO
    }
    if (values.form.fields.resume instanceof File) {
      formData.append(
        "candidate[form][fields][resume]",
        values.form.fields.resume
      );
    } else if (values.form.fields.resume?.url) {
      // TODO
    }
    formData.append(
      "candidate[form][fields][first_name]",
      values.form.fields.first_name || ""
    );
    formData.append(
      "candidate[form][fields][last_name]",
      values.form.fields.last_name || ""
    );
    if (values.form.fields.phone_number) {
      formData.append(
        "candidate[form][fields][phone_number]",
        values.form.fields.phone_number
      );
    }
    if (values.form.fields.address.cep) {
      formData.append(
        "candidate[form][fields][address][cep]",
        values.form.fields.address.cep
      );
      formData.append(
        "candidate[form][fields][address][address_1]",
        values.form.fields.address.address_1 || ""
      );
      formData.append(
        "candidate[form][fields][address][address_2]",
        values.form.fields.address.address_2 || ""
      );
      formData.append(
        "candidate[form][fields][address][district]",
        values.form.fields.address.district || ""
      );
      formData.append(
        "candidate[form][fields][address][city]",
        values.form.fields.address.city || ""
      );
      formData.append(
        "candidate[form][fields][address][state]",
        values.form.fields.address.state || ""
      );
    }
    if (values.form.fields.summary) {
      formData.append(
        "candidate[form][fields][summary]",
        values.form.fields.summary
      );
    }
    if (values.form.fields.cover_letter) {
      formData.append(
        "candidate[form][fields][cover_letter]",
        values.form.fields.cover_letter
      );
    }
    if (values.form.fields.linkedin) {
      formData.append(
        "candidate[form][fields][linkedin]",
        values.form.fields.linkedin
      );
    }
    if (values.form.fields.instagram) {
      formData.append(
        "candidate[form][fields][instagram]",
        values.form.fields.instagram
      );
    }
    if (values.form.fields.tiktok) {
      formData.append(
        "candidate[form][fields][tiktok]",
        values.form.fields.tiktok
      );
    }
    if (values.form.fields.github) {
      formData.append(
        "candidate[form][fields][github]",
        values.form.fields.github
      );
    }
    if (values.form.fields.facebook) {
      formData.append(
        "candidate[form][fields][facebook]",
        values.form.fields.facebook
      );
    }
    values.form.fields.educations.forEach((education, index) => {
      if (education?.id) {
        formData.append(
          `candidate[form][fields][educations][${index}][id]`,
          education.id.toString()
        );
      }
      formData.append(
        `candidate[form][fields][educations][${index}][school]`,
        education.school || ""
      );
      formData.append(
        `candidate[form][fields][educations][${index}][degree]`,
        education.degree || ""
      );
      formData.append(
        `candidate[form][fields][educations][${index}][field_of_study]`,
        education.field_of_study || ""
      );
      formData.append(
        `candidate[form][fields][educations][${index}][start_date]`,
        education.start_date ? format(education.start_date, dateFormat) : ""
      );
      formData.append(
        `candidate[form][fields][educations][${index}][end_date]`,
        education.end_date ? format(education.end_date, dateFormat) : ""
      );
      if (education?._destroy) {
        formData.append(
          `candidate[form][fields][educations][${index}][_destroy]`,
          education._destroy.toString()
        );
      }
    });
    values.form.fields.work_experiences.forEach((work_experience, index) => {
      if (work_experience?.id) {
        formData.append(
          `candidate[form][fields][work_experiences][${index}][id]`,
          work_experience.id.toString()
        );
      }
      formData.append(
        `candidate[form][fields][work_experiences][${index}][title]`,
        work_experience.title || ""
      );
      formData.append(
        `candidate[form][fields][work_experiences][${index}][company]`,
        work_experience.company || ""
      );
      formData.append(
        `candidate[form][fields][work_experiences][${index}][description]`,
        work_experience.description || ""
      );
      formData.append(
        `candidate[form][fields][work_experiences][${index}][start_date]`,
        work_experience.start_date
          ? format(work_experience.start_date, dateFormat)
          : ""
      );
      formData.append(
        `candidate[form][fields][work_experiences][${index}][end_date]`,
        work_experience.end_date
          ? format(work_experience.end_date, dateFormat)
          : ""
      );
      formData.append(
        `candidate[form][fields][work_experiences][${index}][is_current]`,
        work_experience.is_current.toString()
      );
      if (work_experience?._destroy) {
        formData.append(
          `candidate[form][fields][work_experiences][${index}][_destroy]`,
          work_experience._destroy.toString()
        );
      }
    });
    values.form.questions.forEach((question, index) => {
      formData.append(
        `candidate[form][questions][${index}][name]`,
        question.name || ""
      );
      formData.append(
        `candidate[form][questions][${index}][type]`,
        question.type || ""
      );
      formData.append(
        `candidate[form][questions][${index}][option]`,
        question.option || ""
      );
      let value = question.value || "";
      if (question.type === "date") {
        value = format(question.value, dateFormat);
      }
      formData.append(`candidate[form][questions][${index}][value]`, value);
    });
    return formData;
  };

  const userFormData = (values: UserCandidateParams) => {
    const formData = new FormData();

    if (values.avatar instanceof File) {
      formData.append("user[avatar]", values.avatar);
    }
    if (values.resume instanceof File) {
      formData.append("user[resume]", values.resume);
    }
    if (values.resume_delete) {
      formData.append("user[resume_delete]", values.resume_delete.toString());
    }
    formData.append("user[first_name]", values.first_name || "");
    formData.append("user[last_name]", values.last_name || "");
    formData.append("user[phone_number]", values.phone_number || "");
    formData.append("user[cep]", values.cep || "");
    formData.append("user[address_1]", values.address_1 || "");
    formData.append("user[address_2]", values.address_2 || "");
    formData.append("user[district]", values.district || "");
    formData.append("user[city]", values.city || "");
    formData.append("user[state]", values.state || "");
    formData.append("user[summary]", values.summary || "");
    formData.append("user[cover_letter]", values.cover_letter || "");
    formData.append("user[linkedin]", values.linkedin || "");
    formData.append("user[instagram]", values.instagram || "");
    formData.append("user[tiktok]", values.tiktok || "");
    formData.append("user[github]", values.github || "");
    formData.append("user[facebook]", values.facebook || "");
    values.educations_attributes.forEach((education, index) => {
      if (education?.id) {
        formData.append(
          `user[educations_attributes][${index}][id]`,
          education.id.toString()
        );
      }
      formData.append(
        `user[educations_attributes][${index}][school]`,
        education.school || ""
      );
      formData.append(
        `user[educations_attributes][${index}][degree]`,
        education.degree || ""
      );
      formData.append(
        `user[educations_attributes][${index}][field_of_study]`,
        education.field_of_study || ""
      );
      formData.append(
        `user[educations_attributes][${index}][start_date]`,
        education.start_date ? format(education.start_date, dateFormat) : ""
      );
      formData.append(
        `user[educations_attributes][${index}][end_date]`,
        education.end_date ? format(education.end_date, dateFormat) : ""
      );
      if (education?._destroy) {
        formData.append(
          `user[educations_attributes][${index}][_destroy]`,
          education._destroy.toString()
        );
      }
    });
    values.work_experiences_attributes.forEach((work_experience, index) => {
      if (work_experience?.id) {
        formData.append(
          `user[work_experiences_attributes][${index}][id]`,
          work_experience.id.toString()
        );
      }
      formData.append(
        `user[work_experiences_attributes][${index}][title]`,
        work_experience.title || ""
      );
      formData.append(
        `user[work_experiences_attributes][${index}][company]`,
        work_experience.company || ""
      );
      formData.append(
        `user[work_experiences_attributes][${index}][description]`,
        work_experience.description || ""
      );
      formData.append(
        `user[work_experiences_attributes][${index}][start_date]`,
        work_experience.start_date
          ? format(work_experience.start_date, dateFormat)
          : ""
      );
      formData.append(
        `user[work_experiences_attributes][${index}][end_date]`,
        work_experience.end_date
          ? format(work_experience.end_date, dateFormat)
          : ""
      );
      formData.append(
        `user[work_experiences_attributes][${index}][is_current]`,
        work_experience.is_current.toString()
      );
      if (work_experience?._destroy) {
        formData.append(
          `user[work_experiences_attributes][${index}][_destroy]`,
          work_experience._destroy.toString()
        );
      }
    });
    return formData;
  };

  const formik = useFormik({
    initialValues: {
      avatar: userState.currentUser.avatar,
      resume: userState.currentUser.resume,
      resume_delete: false,
      first_name: userState.currentUser.first_name,
      last_name: userState.currentUser.last_name,
      phone_number: maskPhoneNumber(userState.currentUser.phone_number),
      cep: maskCep(userState.currentUser.cep),
      address_1: userState.currentUser.address_1,
      address_2: userState.currentUser.address_2,
      district: userState.currentUser.district,
      city: userState.currentUser.city,
      state: userState.currentUser.state,
      summary: userState.currentUser.summary,
      cover_letter: userState.currentUser.cover_letter,
      linkedin: userState.currentUser.linkedin,
      instagram: userState.currentUser.instagram,
      tiktok: userState.currentUser.tiktok,
      github: userState.currentUser.github,
      facebook: userState.currentUser.facebook,
      educations_attributes: userState.currentUser.educations.map(
        (education) => ({
          ...education,
          start_date: education.start_date
            ? parseISO(education.start_date)
            : null,
          end_date: education.end_date ? parseISO(education.end_date) : null,
        })
      ),
      work_experiences_attributes: userState.currentUser.work_experiences.map(
        (work_experience) => ({
          ...work_experience,
          start_date: work_experience.start_date
            ? parseISO(work_experience.start_date)
            : null,
          end_date: work_experience.end_date
            ? parseISO(work_experience.end_date)
            : null,
        })
      ),
    } as UserCandidateParams,
    onSubmit: async (values, formikBag) => {
      dispatch(
        updateCandidate({
          params: userFormData(values),
          formikBag: formikBag,
        })
      );
    },
    enableReinitialize: true,
  });

  const candidateFormik = useFormik({
    initialValues: {
      form: {
        fields: {
          avatar: userState.currentUser.avatar,
          resume: userState.currentUser.resume,
          first_name: userState.currentUser.first_name,
          last_name: userState.currentUser.last_name,
          phone_number: maskPhoneNumber(userState.currentUser.phone_number),
          address: {
            cep: maskCep(userState.currentUser.cep),
            address_1: userState.currentUser.address_1,
            address_2: userState.currentUser.address_2,
            district: userState.currentUser.district,
            city: userState.currentUser.city,
            state: userState.currentUser.state,
          },
          summary: userState.currentUser.summary,
          cover_letter: userState.currentUser.cover_letter,
          linkedin: userState.currentUser.linkedin,
          instagram: userState.currentUser.instagram,
          tiktok: userState.currentUser.tiktok,
          github: userState.currentUser.github,
          facebook: userState.currentUser.facebook,
          educations: userState.currentUser.educations.map((education) => ({
            ...education,
            start_date: education.start_date
              ? parseISO(education.start_date)
              : null,
            end_date: education.end_date ? parseISO(education.end_date) : null,
          })),
          work_experiences: userState.currentUser.work_experiences.map(
            (work_experience) => ({
              ...work_experience,
              start_date: work_experience.start_date
                ? parseISO(work_experience.start_date)
                : null,
              end_date: work_experience.end_date
                ? parseISO(work_experience.end_date)
                : null,
            })
          ),
        },
        questions:
          jobState.job?.form.questions.map((question) => ({
            ...question,
            value: "",
          })) || [],
      },
    } as CandidateParams,
    onSubmit: async (values, formikBag) => {
      if (jobState.job?.id) {
        dispatch(
          createCandidate({
            params: await candidateFormData(values),
            jobId: jobState.job.id.toString(),
            formikBag,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  return { formik, candidateFormik };
}
