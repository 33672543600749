import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearState, jobSelector } from "../../redux/job/jobSlice";
import { Link, useParams } from "react-router-dom";
import { showJob } from "../../redux/job/jobActions";
import PageNotFound from "../PageNotFound";
import Backdrop from "../../components/Backdrop";
import Footer from "../../components/Company/Footer";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { WorkingType, WorkingModel, State } from "../../utils/types";
import { DepartmentIndexAttributes } from "../../redux/department/departmentApi";
import { BenefitIndexAttributes } from "../../redux/benefit/benefitApi";
import Header from "../../components/Company/Header";
import { LoadingButton } from "@mui/lab";
import urls from "../../utils/urls";
import JobHeader from "./JobHeader";

export interface JobData {
  name: string | null;
  working_type: WorkingType | null;
  working_type_visible: boolean;
  working_model: WorkingModel | null;
  state: State | null;
  city: string | null;
  description: string | null;
  is_pwd: boolean;
  minimum_salary_range: string | null;
  maximum_salary_range: string | null;
  salary_range_visible: boolean;
  department_visible: boolean;
  department: DepartmentIndexAttributes | null;
  requirements: string[];
  benefits: BenefitIndexAttributes[];
  published_at: Date | null;
  company: {
    logo: string | null;
    banner: string | null;
    name: string | null;
    slogan: string | null;
    primary_color: string | null;
  };
}

interface IJob {
  isPreview?: boolean;
  previewData?: JobData;
}

const Job: React.FC<IJob> = ({ isPreview, previewData }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { slug, job_id } = useParams<{ slug: string; job_id: string }>();

  const jobState = useAppSelector(jobSelector);

  const [data, setData] = useState<JobData | null>(previewData || null);
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (job_id && !isPreview) {
      dispatch(showJob(job_id));
    }
  }, [job_id, isPreview]);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (jobState.isFindingSuccess && !isPreview) {
      setData(jobState.job);
    }
  }, [jobState.isFindingSuccess, isPreview]);

  useEffect(() => {
    if (jobState.isError && !isPreview) {
      setPageNotFound(true);
    }
  }, [jobState.isError, isPreview]);

  useEffect(() => {
    if (previewData) {
      setData(previewData);
    }
  }, [previewData]);

  if (pageNotFound) {
    return <PageNotFound />;
  }

  if (jobState.isFinding) {
    return <Backdrop />;
  }

  return (
    <>
      <Header
        banner={data?.company?.banner?.toString()}
        logo={data?.company?.logo?.toString()}
        name={data?.company?.name?.toString()}
        slogan={data?.company?.slogan?.toString()}
        slug={slug}
      />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <JobHeader job={data} />

        {data?.description && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Descrição da vaga</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              ></Typography>
            </Box>
          </Box>
        )}

        {data?.requirements && data.requirements.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Requisitos</Typography>
            <Box sx={{ mt: 2 }}>
              <ul>
                {data.requirements.map((requirement, index) => (
                  <li key={index}>
                    <Typography>{requirement}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        )}

        {data?.benefits && data.benefits.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5">Benefícios</Typography>
            <Box sx={{ mt: 2 }}>
              <ul>
                {data.benefits.map((benefit, index) => (
                  <li key={index}>
                    <Typography>{benefit.name}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Link
            to={urls.companyApplyJobPage
              .replace(":slug", slug || "")
              .replace(":job_id", job_id || "")}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              sx={{
                backgroundColor:
                  data?.company?.primary_color || theme.palette.primary.main,
                "&:hover": {
                  backgroundColor:
                    data?.company?.primary_color || theme.palette.primary.main,
                },
              }}
            >
              Aplicar
            </LoadingButton>
          </Link>
        </Box>
      </Container>

      <Footer />
    </>
  );
};

export default Job;
