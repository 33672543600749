import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { Delete, Visibility } from "@mui/icons-material";
import { FormikProps } from "formik";
import { Link } from "react-router-dom";

interface FileWithUrl extends File {
  url?: string;
}

interface IDropzone {
  formik: FormikProps<any>;
  field: string;
  label?: string;
  supportedFiles?: string[];
  maxFiles?: number;
  helperText?: string | null;
  disableDelete?: boolean;
  color?: string | null;
}

const DropzoneContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDragging",
})<{ isDragging: boolean; bgColor: string }>(({ isDragging, bgColor }) => ({
  border: isDragging ? `2px dashed ${bgColor}` : "2px dashed #ccc",
  borderRadius: "8px",
  padding: "20px",
  textAlign: "center",
  maxWidth: "400px",
  width: "100%",
  transition: "border-color 0.3s, box-shadow 0.3s",
  boxShadow: isDragging ? "0px 4px 12px rgba(0, 0, 0, 0.1)" : "none",
  backgroundColor: "#f9f9f9",
  "&:hover": {
    borderColor: bgColor,
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const FileList = styled(List)({
  listStyle: "none",
  padding: "0",
  marginTop: 20,
  backgroundColor: "#fff",
  borderRadius: 6,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  "& li": {
    gap: 4,
    borderRadius: 6,
  },
});

const FileItem = styled(ListItem)({
  background: "#fff",
  borderBottom: "1px solid #ddd",
  "&:last-child": {
    borderBottom: "none",
  },
});

const Dropzone: React.FC<IDropzone> = ({
  formik,
  field,
  label,
  supportedFiles = ["image/jpeg", "image/png"],
  maxFiles = 1,
  helperText,
  disableDelete = false,
  color,
}) => {
  const theme = useTheme();

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
    setIsDragging(true);
  }, []);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const droppedFiles = Array.from(e.dataTransfer.files) as File[];

      const validated = validateFiles(droppedFiles);
      if (!validated) return;

      if (maxFiles === 1) {
        formik.setFieldValue(field, droppedFiles[0]);
        formik.setFieldValue(`${field}_delete`, false);
      } else {
        formik.setFieldValue(field, [
          ...eval(`formik.values.${field}`),
          ...droppedFiles.slice(
            0,
            maxFiles - eval(`formik.values.${field}`).length
          ),
        ]);
      }
      setIsDragging(false);
    },
    [formik, field, maxFiles]
  );

  const handleFilesUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const selectedFiles = Array.from(e.target.files) as File[];

      const validated = validateFiles(selectedFiles);
      if (!validated) {
        return;
      }

      if (maxFiles === 1) {
        formik.setFieldValue(field, selectedFiles[0]);
        formik.setFieldValue(`${field}_delete`, false);
      } else {
        formik.setFieldValue(field, [
          ...eval(`formik.values.${field}`),
          ...selectedFiles.slice(
            0,
            maxFiles - eval(`formik.values.${field}`).length
          ),
        ]);
      }
    },
    [formik, field, maxFiles]
  );

  const removeFile = useCallback(
    (index: number) => {
      if (maxFiles === 1) {
        formik.setFieldValue(field, null);
        formik.setFieldValue(`${field}_delete`, true);
      } else {
        const newFiles = [...eval(`formik.values.${field}`)];
        newFiles.splice(index, 1);
        formik.setFieldValue(field, newFiles);
      }
    },
    [formik, field, maxFiles]
  );

  const validateFiles = (files: File[]) => {
    if (files.length > maxFiles) {
      formik.setFieldError(
        field,
        `Você pode enviar no máximo ${maxFiles} arquivos`
      );
      return false;
    }

    if (
      supportedFiles.length > 0 &&
      files.some((file) => !supportedFiles.includes(file.type))
    ) {
      formik.setFieldError(
        field,
        `Somente arquivos ${supportedFiles
          .map((fileType) => fileType.split("/")[1])
          .join(", ")} são permitidos`
      );
      return false;
    }

    formik.setFieldError(field, "");
    return true;
  };

  const currentFiles =
    maxFiles === 1
      ? eval(`formik.values.${field}`)
        ? [eval(`formik.values.${field}`)]
        : []
      : eval(`formik.values.${field}`);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {label && <Typography fontWeight="bold">{label}</Typography>}
      <DropzoneContainer
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        bgColor={color || theme.palette.primary.main}
      >
        <Typography>
          Arraste e solte arquivo{maxFiles !== 1 ? "s" : ""} aqui
        </Typography>
        <Box
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Divider sx={{ width: "50px" }} />
          <Typography sx={{ px: 2 }}>Ou</Typography>
          <Divider sx={{ width: "50px" }} />
        </Box>
        <HiddenInput
          type="file"
          multiple
          onChange={handleFilesUpload}
          id="fileInput"
        />
        <label htmlFor="fileInput">
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{
              backgroundColor: color || theme.palette.primary.main,
              "&:hover": {
                backgroundColor: color || theme.palette.primary.main,
              },
            }}
          >
            Selecionar arquivo{maxFiles !== 1 ? "s" : ""}
          </Button>
        </label>
        <FileList>
          {currentFiles?.map((file: FileWithUrl, index: number) => (
            <FileItem key={index}>
              {file?.url ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexGrow: 1,
                  }}
                >
                  <Link to={file?.url} target="_blank">
                    <ListItemText
                      primary={file.name}
                      sx={{ wordBreak: "break-word" }}
                    />
                  </Link>
                  <Link to={file?.url} target="_blank">
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </Link>
                </Box>
              ) : (
                <ListItemText
                  primary={file.name}
                  sx={{ wordBreak: "break-word" }}
                />
              )}
              {!disableDelete && (
                <IconButton onClick={() => removeFile(index)}>
                  <Delete />
                </IconButton>
              )}
            </FileItem>
          ))}
        </FileList>
      </DropzoneContainer>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};

export default Dropzone;
