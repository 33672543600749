import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import { FormQuestion, State } from "../../utils/types";
import { Education } from "../../components/MultiInput/Education";
import { WorkExperience } from "../../components/MultiInput/WorkExperience";

type FormQuestionWithValue = FormQuestion & {
  value: string;
};

interface FormParams {
  fields: {
    first_name: string;
    last_name: string;
    avatar: File | string | null;
    resume: File | { name: string; url: string } | null;
    phone_number: string | null;
    address: {
      cep: string | null;
      address_1: string | null;
      address_2: string | null;
      district: string | null;
      city: string | null;
      state: State;
    };
    summary: string | null;
    cover_letter: string | null;
    linkedin: string | null;
    instagram: string | null;
    tiktok: string | null;
    github: string | null;
    facebook: string | null;
    educations: Education[];
    work_experiences: WorkExperience[];
  };
  questions: FormQuestionWithValue[];
}

export interface CandidateParams {
  form: FormParams;
}

export interface CandidateShowResponse {
  id: number;
  user: {
    id: number;
    name: string;
  };
  job: {
    id: number;
    name: string;
  };
  form: FormParams;
}

export interface CandidateUpdateParams {
  id: number;
  rating?: number;
  rank_order_position?: number;
  job_pipeline_id?: number;
}

export interface CandidateResponse
  extends CandidateShowResponse,
    ResponseWithErrors {}

const create = async (
  formData: FormData,
  jobId: string
): Promise<AxiosResponse<CandidateResponse>> => {
  return await requestHelper
    .post(`candidates/${jobId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch((e) => e.response);
};

const update = async (
  params: CandidateUpdateParams
): Promise<AxiosResponse<CandidateResponse>> => {
  return await requestHelper
    .patch(`candidates/${params.id}`, {
      candidate: params,
    })
    .catch((e) => e.response);
};

export { create, update };
